<template>
  <van-dialog class="pay-dialog" v-model="show" :show-confirm-button="false">
    <div class="content">
      <template v-if="step == 0">
        <h3 class="title">成为网站内部会员</h3>
        <div class="package-wrap">
          <div
            :key="item.pid"
            v-for="item in packages"
            class="package-item"
            :class="{ active: pid == item.pid }"
            @click="choosePackage(item.pid)"
          >
            <p class="name">{{ item.name }}</p>
            <p class="price">￥{{ item.price }}元</p>
            <p class="tip">
              平均
              <span class="num"
                >{{
                  Math.round((item.price * 100) / item.duration) / 100
                }}元 </span
              >/天
            </p>
          </div>
        </div>

        <div class="pay-wrap" v-if="isMobile && !isWeixin">
          <div class="pay-tip-wrap">
            <p class="em danger">抱歉，暂不支持移动端页面支付…</p>
            <p>可用通道：</p>
            <ul>
              <li>1、电脑端访问捐赠</li>
              <li>
                2、微信公众号【万网搜索】捐赠
                <van-button type="primary" size="mini" @click="copy('万网搜索')"
                  >复制公众号名称</van-button
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="pay-wrap" v-else>
          <p class="label">支付方式：</p>
          <div class="paytype-wrap">
            <div
              class="paytype"
              v-if="!isMobile || isWeixin"
              :class="{ active: paytype == 'wxpay' }"
              @click="choosePaytype('wxpay')"
            >
              <van-icon class="wxpay" name="wechat" /> 微信支付
            </div>
          </div>
          <div class="button-wrap">
            <van-button type="primary" @click="createOrder"
              >提交订单</van-button
            >
          </div>
        </div>
      </template>

      <template v-if="step == 1">
        <h3 class="title">扫码支付</h3>
        <div class="qrcode-wrap">
          <template v-if="!this.isMobile && !this.isWeixin">
            <img
              :src="qrcode"
              @error="qrcodeError = true"
              class="qrcode"
              alt="支付二维码加载失败"
            />
            <p
              class="tip"
              v-if="paytype == 'alipay' && !qrcodeExpired && !qrcodeError"
            >
              <van-icon name="alipay" class="alipay" />请用支付宝扫码支付
            </p>
            <p
              class="tip"
              v-if="paytype == 'wxpay' && !qrcodeExpired && !qrcodeError"
            >
              <van-icon name="wechat" class="wxpay" />请用微信扫码扫码支付
            </p>
            <p class="tip danger" v-if="!qrcodeExpired && !qrcodeError">
              支付结果返回前请勿关闭当前窗口
            </p>
          </template>
          <template v-if="this.isMobile || this.isWeixin">
            <img
              src="https://res.hexiaotu.com/zhi/pay_confirm.png"
              class="confirm"
              alt="支付扫码"
            />
          </template>

          <p class="tip danger" v-if="qrcodeExpired" @click="retry">
            订单已超时，点击重新创建订单 <van-icon name="replay" />
          </p>
        </div>

        <div class="button-wrap">
          <van-button @click="retry">重新选择套餐</van-button>
          <van-button type="primary" @click="confirmOrder">我已支付</van-button>
        </div>
      </template>

      <template v-if="step == 2">
        <h3>充值成功</h3>
        <div class="success-wrap">
          <!-- <van-icon name="success" class="success" /> -->
          <lottie-player
            class="gift-animation"
            src="https://res.hexiaotu.com/pan/lottie/gift.json"
            background="transparent"
            speed="0.8"
            autoplay
          />
          <p class="tip">💐恭喜您已成为本站内部会员</p>
        </div>
        <div class="button-wrap">
          <van-button type="primary" @click="hidePayDialog">确定</van-button>
        </div>
      </template>
      <div class="section" v-if="userinfo && userinfo.showBind">
        <div class="info-line">
          <div class="left">
            <p class="small bold">之前已是会员，绑定原有账户。</p>
          </div>
          <van-button size="mini" round type="primary" @click="showBindDialog"
            >点此绑定</van-button
          >
        </div>
      </div>
      <p class="warn-tip">
        声明：<span class="em">网站已升级为内部会员专享</span
        >，搜索引擎结果具备动态不确定性，如介意结果不满意，请勿捐赠，有问题请联系：shakanamo945@gmail.com
      </p>
    </div>
    <van-icon class="close" name="cross" @click="hidePayDialog" />
  </van-dialog>
</template>

<script>
import { getPayPackages, queryOrder, createOrder } from "@/api/pay";
import copyToClipboard from "../utils/clipboard";

export default {
  data() {
    return {
      step: 0,
      pid: "",
      show: true,
      packages: [],
      paytype: "wxpay",
      qrcodeError: false,
      qrcodeExpired: false,
      qrcodeTimer: null,
      order_id: "",
      payurl: "",
      qrcode: "",
      channel: "official",
    };
  },
  async created() {
    const packages = await getPayPackages();
    this.packages = packages;
    if (this.isWeixin) this.choosePaytype("wxpay");
    this.pid = this.packages[0].pid;
  },
  destroyed() {
    this.qrcodeTimer && clearInterval(this.qrcodeTimer);
  },
  methods: {
    copy(content) {
      copyToClipboard(content);
      this.$toast.success("复制成功");
    },
    showBindDialog() {
      this.showBindUserDialog();
      this.hidePayDialog();
    },
    choosePackage(pid) {
      this.pid = pid;
    },
    choosePaytype(paytype) {
      this.paytype = paytype;
    },
    async createOrder() {
      this.$toast.loading("正在调起支付窗口");

      let paytype = this.paytype;
      if (!this.isMobile && this.paytype == "wxpay") {
        // paytype = 'native'
        this.channel = "official";
      }

      if (this.isMobile && this.isWeixin) {
        paytype = "jsapi";
      }

      const res = await createOrder({
        pid: this.pid,
        plt: this.isMobile ? "mobile" : "pc",
        client: this.client,
        paytype: paytype,
      });

      if (res.code == 0) {
        this.step = 1;
        const { order_id, payurl, qrcode, jsapi } = res.data;
        this.order_id = order_id;
        this.qrcode = qrcode;
        this.payurl = payurl;

        if (this.paytype == "alipay" && !this.isWeixin && this.isMobile) {
          window.location.href = payurl;
        } else if (this.isWeixin || paytype == "jsapi") {
          return (window.location.href = payurl);
        }

        // 查询订单支付状态
        let queryTimes = 0;
        this.qrcodeTimer = setInterval(async () => {
          const res = await queryOrder({
            order_id: this.order_id,
            type: this.channel,
          });
          if (res.code == 0) {
            await this.paySuccess();
          } else {
            queryTimes++;
            if (queryTimes > 60) {
              this.qrcodeExpired = true;
              this.qrcodeTimer && clearInterval(this.qrcodeTimer);
            }
          }
        }, 1500);
      } else {
        this.$toast.fail("创建订单出错，请稍后重试");
      }
    },
    async confirmOrder() {
      const res = await queryOrder({
        order_id: this.order_id,
        type: this.channel,
      });
      if (res.code == 0) {
        await this.paySuccess();
      } else if (res.code == 10302) {
        return this.$toast.fail("检测到尚未支付");
      } else if (res.code == 10304) {
        return this.$toast.fail("支付平台出错，请联系网站管理员");
      } else if (res.code == 10303) {
        return this.$toast.fail("订单已过期");
      } else {
        this.$toast.fail("出现错误，请稍后重试");
      }
    },
    retry() {
      this.step = 0;
      this.order_id = "";
      this.qrcode = "";
      this.payurl = "";
      this.qrcodeExpired = false;
      this.qrcodeTimer && clearInterval(this.qrcodeTimer);
    },
    async paySuccess() {
      this.step = 2;
      await this.updateUserinfo();
      this.qrcodeTimer && clearInterval(this.qrcodeTimer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.section {
  margin-top: 12px;
  padding: 12px 10px;
  border-radius: 4px;
  background-color: rgba($theme-color, 0.1);
  .info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    text-align: left;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .left {
    font-weight: bold;
  }
  .van-button {
    padding: 0 10px;
    flex: none;
    margin-left: 10px;
  }
}

.pay-dialog {
  width: 360px;
  border-radius: 4px;
  top: 40%;
  h3 {
    font-size: 18px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .intro {
    font-size: 12px;
    color: #666;
    text-align: justify;
    margin-bottom: 10px;
  }
  .content {
    padding: 0px 20px 20px;
  }
  .package-wrap {
    display: flex;
    justify-content: space-between;
    .package-item {
      width: 48%;
      padding: 20px 10px;
      border-radius: 4px;
      border: 1px solid #ddd;
      text-decoration: none;
      text-align: center;
      color: #666;
      font-size: 13px;
      transition: all ease 0.2s;
      .name {
        font-size: 18px;
        font-weight: bold;
      }
      &:hover,
      &.active {
        cursor: pointer;
        border-color: $theme-color;
        color: $theme-color;
      }
      .price {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
      }
      .tip {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px dashed #eee;
        .num {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .label {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pay-wrap {
    margin-top: 15px;
    .pay-tip-wrap {
      padding: 10px;
      background-color: #f5f5f5;
      font-size: 13px;
      line-height: 1.8;
      font-weight: bold;
      ul {
        list-style: disc;
        padding-left: 20px;
      }
    }
    .paytype-wrap {
      display: flex;
    }
    .paytype {
      display: inline-block;
      font-size: 13px;
      padding: 8px 20px;
      border-radius: 2px;
      margin-right: 10px;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      transition: all ease 0.2s;
      .alipay {
        color: #1296db;
      }
      .wxpay {
        color: #45bb35;
      }
      i {
        font-size: 16px;
        margin-right: 5px;
      }
      &:hover,
      &.active {
        cursor: pointer;
        border-color: $theme-color;
      }
    }
  }

  .tip-wrap {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .qrcode-wrap {
    text-align: center;
    .confirm {
      width: 240px;
    }
    .qrcode {
      padding: 10px;
      border: 1px solid #eee;
      background-color: #f5f5f5;
      border-radius: 4px;
    }
    .tip {
      margin-top: 5px;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #666;
      justify-content: center;
      i {
        font-size: 20px;
      }
      &.danger {
        cursor: pointer;
        font-weight: bold;
        i {
          font-size: 15px;
          margin-left: 4px;
        }
      }
    }
  }

  .success-wrap {
    text-align: center;
    i {
      font-size: 80px;
    }
    .gift-animation {
      height: 120px;
      width: 120px;
    }
    .tip {
      margin-top: 10px;
      font-size: 13px;
      .num {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .button-wrap {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    .van-button {
      height: 40px;
      width: 44%;
      margin-right: 20px;
      border-radius: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .warn-tip {
    margin-top: 10px;
    padding: 5px;
    color: rgb(128, 82, 50);
    font-size: 12px;
    border-radius: 2px;
    background-color: #fffbe8;
    border: 1px solid rgba(73, 33, 5, 0.2);
  }
}

.van-dialog {
  overflow: visible;
}

.close {
  position: absolute;
  height: 36px;
  width: 36px;
  bottom: -46px;
  left: 50%;
  margin-left: -18px;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  line-height: 36px;
  text-align: center;
  background-color: rgba(#000, 0.7);
  &:hover {
    cursor: pointer;
    background-color: #000;
  }
}

@media (max-width: 700px) {
  .pay-dialog {
    width: 90%;
    top: 45%;
    h3 {
      font-size: 15px;
    }
  }
}
</style>
